import Store from "store";
import {
  Config
} from "../../utils/index";
import {
  Message
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";

const baseUrl = Config.api.url;

export default async (type = "GET", url = "", data = {}) => {
  type = type.toUpperCase();
  url = baseUrl + url;
  let aData = []; // 存储数据
  let sData = ""; // 拼接数据
  let formData = new FormData();
  if (type === "GET") {
    for (let attr in data) {
      aData.push(attr + "=" + data[attr]);
    }
    sData = aData.join("&");
    if (sData) {
      url = url + "?" + sData + '&t=' + new Date().getTime();
    } else {
      if (url.indexOf('?') > 0) {
        url = url + '&t=' + new Date().getTime();
      } else {
        url = url + "?t=" + new Date().getTime()
      }
    }
  } else if (type == "UPLOAD") {
    for (let item in data) {
      formData.append(`${item}`, data[item]);
    }
  } else if (type == "UPLOADMUL") {
    for (let item in data) {
      if (data[item] && (typeof data[item] == 'object') && item.length) {
        data[item].map((fileItem) => {
          formData.append(`${item}`, fileItem);
        })
      } else {
        formData.append(`${item}`, data[item]);
      }
      
    }
  } else {
    sData = JSON.stringify(data)
  }
  let token = "";
  let localToken = Store.get(Config.store.token);
  if (localToken) token = localToken;
  return new Promise((resolve, reject) => {
    let requestObj;
    if (window.XMLHttpRequest) {
      requestObj = new XMLHttpRequest();
    }
    if (type == 'UPLOAD' || type == 'UPLOADMUL') {
      requestObj.open("POST", url, true);
      requestObj.setRequestHeader("Authorization", token); // 设置token
      requestObj.send(formData);
    } else {
      requestObj.open(type, url, true);
      requestObj.setRequestHeader(
        "Content-type",
        "application/json"
      );
      requestObj.setRequestHeader("Authorization", token); // 设置token
      requestObj.send(sData);
    }
    requestObj.onreadystatechange = () => {
      if (requestObj.readyState === 4) {
        if (requestObj.status === 200) {
          let obj = requestObj.response;
          if (typeof obj !== "object") {
            obj = JSON.parse(obj);
          }
          if (obj.code == "401") {
            window.location.href = "/login";
            return;
          }
          if (obj.code === Config.api.success) {
            Vue.prototype.$vuex.commit('btnLoading', false)
            resolve(obj);
            return;
          } else {
            if (
              obj.code !== "200"
            ) {
              Vue.prototype.$vuex.commit('btnLoading', false)
              Message({
                showClose: true,
                message: `${obj.message}`,
                type: "error"
              });
            }
            resolve(obj);
            return;
          }
        } else {
          reject(requestObj);
        }
      }
    };
  });
};