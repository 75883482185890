import Vue from 'vue'
import Router from 'vue-router'
import { Config, setPageTitle,routeMenu,quotedMenu, receiveOrderMenu } from '../utils'
import { constantRouterMap, asyncRouterMap } from '../utils/routerConfig'

Vue.use(Router)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const getRouterData = () => {
    const constantRoutes = Object.values(constantRouterMap).map(item => {
        return {
            path: item.path,
            name: item.name,
            component: () => import(`../pages/${item.component}`),
            meta: item.meta
        }
    })
    const asyncRoutes = Object.values(asyncRouterMap).map(item => {
        return {
            path: item.path,
            name: item.name,
            component: () => import(`../pages/${item.component}`),
            meta: item.meta
        }
    })
    const router = new Router({
        base: Config.baseRouter,
        mode: 'history',
        routes: [
            ...constantRoutes,
            {
                path: "/",
                name: "Layout",
                redirect: '/index',
                component: () => import(`../pages/layout.vue`),
                meta: {
                    title: "帮客智能客服"
                },
                children: asyncRoutes
            },
        ]
    })
    beforeRouter(router);
    afterRouter(router);
    return router;
}

// 全局前置钩子
function beforeRouter(router) {
    router.beforeEach((to, from, next) => {
        if (routeMenu.includes(to.path) || receiveOrderMenu.includes(to.path)) { 
            Vue.prototype.$vuex.commit("menuFlag", true);
            if (quotedMenu.includes(to.path)) {
                Vue.prototype.$vuex.commit("activeIndex", "/quoted/quotes");
            } else if (receiveOrderMenu.includes(to.path)) {
                Vue.prototype.$vuex.commit("activeIndex", "/assign-order/receive-list");
            } else {
               Vue.prototype.$vuex.commit("activeIndex", to.path); 
            }
            
        } else {
            Vue.prototype.$vuex.commit("menuFlag", false);
            Vue.prototype.$vuex.commit("activeIndex", "/index");
        }
        if (to.path == '/index') {
            Vue.prototype.$vuex.commit("defaultOpeneds", []);
        }
        
        next();
    });
}

// 全局后置钩子
function afterRouter(router) {
    router.afterEach(transition => {
        window.scrollTo(0, 0);
        const title = transition.meta.title;
        setPageTitle(title);
        // console.log(transition,'to-------------------');
        
    });
}