import Store from "store";
import Vuex from "vuex";
import Vue from "vue"
import {
    Config
} from "../../utils/index.js";
import {
    userData,
    noReadNum,
    waitOrderNum
} from "../../service/common.js";
// 初始化 state
const state = {
    getMenuInfo: [],
    getUserIcon: "", // 接单中心，待接单数
    waitOrderNum: 0,
    getNickName: "",
    getUserInfo: {},
    btnLoading: false,
    isReadNum: {},
    menuFlag: false,
    onlyCompany: false, // 是否只有一家公司
    defaultOpeneds: [],
    activeIndex: "/index"
};
// getters
const getters = {
    getMenuInfo: state => state.getMenuInfo,
    getUserIcon: state => state.getUserIcon,
    waitOrderNum: state => state.waitOrderNum,
    getNickName: state => state.getNickName,
    btnLoading: state => state.btnLoading,
    getUserInfo: state => state.getUserInfo,
    isReadNum: state => state.isReadNum,
    menuFlag: state => state.menuFlag,
    defaultOpeneds: state => state.defaultOpeneds,
    activeIndex: state => state.activeIndex,
    onlyCompany: state => state.onlyCompany,
};

// actions
const actions = {
    async getMenuInfo({
        commit
    }) {
        const res = await userData();
        // 剔除菜单，保留用户信息存储到本地 并传给header组件
        commit('getMenuInfo', res);
    },
    async waitOrderNum({ commit }, companyId) {
        const res = await waitOrderNum({companyId});
        commit('waitOrderNum', res);
    },
    async isReadNum({
        commit
    }) {
        const resData = (await noReadNum()).data
        // 剔除菜单，保留用户信息存储到本地 并传给header组件
        commit('isReadNum', resData);
    }
};

// mutations
const mutations = {
    getMenuInfo(state, res) {
        state.getMenuInfo = res.data.menus;
        state.getUserIcon = res.data.icon;
        state.getNickName = res.data.nick_name;
        let userInfo = {
            ...res.data
        }
        delete userInfo.menus
        Store.set(Config.constants.userInfo, userInfo)
    },
    getUserIcon(state, url) {
        state.getUserIcon = url;
    },
    waitOrderNum(state, res) {
        state.waitOrderNum = res.data;
    },
    getUserInfo(state, url) {
        state.getUserInfo = url;
    },
    btnLoading(state, value) {
        state.btnLoading = value;
    },
    isReadNum(state, value) {
        state.isReadNum = value;
    },
    menuFlag(state, value) {
        state.menuFlag = value
    },
    defaultOpeneds(state, value) {
        state.defaultOpeneds = value
    },
    activeIndex(state, value) {
        state.activeIndex = value
    },
    onlyCompany(state, value) {
        state.onlyCompany = value
    },
};
Vue.use(Vuex)
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})