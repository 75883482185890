// import favicon from "../assets/img/logo.png"
// import Ip from "../../public/ip.js"
// import Vue from "vue"
import moment from 'moment'

const API_ENV = process.env.VUE_APP_API;
const NODE_ENV = process.env.NODE_ENV;

const localApi = "/api";
const devApi = "http://localhost:8082/api";
const proApi = window.location.protocol + "//" + window.location.host + "/api";

const api = {
  local: localApi,
  dev: devApi,
  prod: proApi
};
const routerapi = { //邀请注册环境
  local: "http://localhost:8082",
  dev: "https://dev2.fwwb.vip",
  prod: "https://dev.fwwb.vip",
};


const deskapi = {

  local: "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E4%B8%89%E5%9C%86%E8%BD%AF%E4%BB%B6%E5%8C%85/dist_electron_dev/%E5%B8%AE%E5%AE%A2%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%20Setup%20",
  development: "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E4%B8%89%E5%9C%86%E8%BD%AF%E4%BB%B6%E5%8C%85/dist_electron_dev/%E5%B8%AE%E5%AE%A2%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%20Setup%20",
  production: "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E4%B8%89%E5%9C%86%E8%BD%AF%E4%BB%B6%E5%8C%85/dist_electron/%E5%B8%AE%E5%AE%A2%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%20Setup%20",
};

// 桌面端下载地址
export const deskDownload =  (window.location.origin.indexOf('dev.fwwb.vip') > -1) ? deskapi.production : deskapi.development


// 基本配置
export const Config = {
  api: {
    url: api[API_ENV],
    success: 200 || '200',
    deskDown: deskapi[NODE_ENV],
    h5Url: routerapi[API_ENV],
  },
  baseRouter: "./",
  test: NODE_ENV === "development" ? 1 : 0,
  store: {
    token: `USER_TOKEN_${API_ENV}`
  },
  constants: {
    cookies: `DSWP_COOKIES_${process.env.NODE_ENV}`,
    userCode: `DSWP_USER_CODE_${process.env.NODE_ENV}`,
    userInfo: `USER_INFO_${process.env.NODE_ENV}`,
    haveCompany: `HAVE_COM_${process.env.NODE_ENV}`,
    haveQa: `HAVE_QA_${process.env.NODE_ENV}`,
  },
  fileType: {
    imgType: ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'JPG', 'PNG', 'GIF', 'JPEG', 'BMP'],  // img
    pdfType: ['pdf', 'PDF'],  // pdf
    excelType: ['xls', 'xlsx', 'xlsb', 'xlsm', 'XLS', 'XLSX', 'XLSB', 'XLSM'],  // excel
    PWEType: ['pptx', 'ppsx', 'ppt', 'pps', 'potx', 'ppsm', 'doc', 'docx', 'dotx','xls', 'xlsx', 'xlsb', 'XLSM', 'PPTX', 'PPSX', 'PPT', 'PPS', 'POTX', 'PPSM', 'DOC', 'DOCX', 'DOTX','XLS', 'XLSX', 'XLSB', 'XLSM'],  // ppt、word、excel
    PWEIType: ['pdf', 'pptx', 'ppsx', 'ppt', 'pps', 'potx', 'ppsm', 'doc', 'docx', 'dotx','xls', 'xlsx', 'xlsb', 'XLSM', 'jpg', 'png', 'gif', 'jpeg', 'bmp','pdf', 'PPTX', 'PPSX', 'PPT', 'PPS', 'POTX', 'PPSM', 'DOC', 'DOCX', 'DOTX','XLS', 'XLSX', 'XLSB', 'XLSM', 'JPG', 'PNG', 'GIF', 'JPEG', 'BMP'],  //pdf、 ppt、word、excel、img
  },
  fileMessage: {
    imgType: 'jpg、png、gif、jpeg、bmp',
    pdfType: 'pdf',
    excelType: 'xls、xlsx、xlsb、xlsm',
    PWEIType: 'xls、xlsx、pdf、doc、docx、pptx、ppt、jpg、png、gif、jpeg、bmp',  // pdf、word、excel、img
  }
};

// 首页头部菜单，== 后续追加
export const routeMenu = ['/header/cloud', '/quoted/quotes', '/quoted/newCus', '/quoted/base-services', '/quoted/setting-content', '/quoted/quoted-setting', '/quoted/appoint', '/quoted/confirm', '/quoted/complete', '/quoted/create-order', '/assign-order/receive-list']
// 头部菜单 -- 接单中心
export const receiveOrderMenu = ['/receive-order/subaccount-list', '/receive-order/subaccount-list/service']
// 首页头部菜单， 报价路由合并
export const quotedMenu = ['/quoted/quotes','/quoted/newCus','/quoted/base-services','/quoted/setting-content','/quoted/quoted-setting','/quoted/appoint','/quoted/confirm','/quoted/complete','/quoted/create-order']

// 设置页面Title
export const setPageTitle = title => {
  document.title = title;
};

/**
 * 深拷贝
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export const deepCopy = (obj, cache = []) => {
  // 不存在或非对象
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const hit = find(cache, c => c.original === obj);
  if (hit) {
    return hit.copy;
  }

  const copy = Array.isArray(obj) ? [] : {};
  // 放入缓存中
  cache.push({
    original: obj,
    copy
  });

  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
};

/**
 * loading样式
 * @return {参数值}
 */
 export const loadingStyle = () => {
  return {
    "element-loading-text": "拼命加载中",
    "element-loading-spinner": "el-icon-loading",
    "element-loading-background": "rgba(0, 0, 0, 0.8)",
  };
};

/**
 * 获取地址栏url后面的参数
 * @param {name} 参数名
 * @return {参数值}
 */
export const getQueryParams = name => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

/**
 * 获取随机字符串
 * @param 随机字符串
 */
export const randomStr = size => {
  const chars = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  let state = "";
  for (let i = 0; i < size; i++) {
    let id = Math.ceil(Math.random() * 35);
    state += chars[id];
  }
  return state;
};

/**
 * 判断手机号码
 * @param {name} 参数名
 * @return {参数值}
 */
export const isTelephone = phone => {
  let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  phone = phone.replace(/[\u202D+\u202C+\s+]/g, "");
  if (!reg.test(phone)) {
    return false;
  } else {
    return true;
  }
};

/**
 * 验证邮箱格式
 * @param {emaill} 参数名
 * @return {参数值}
 */

export const isEmaill = (rule, value, callback) => {
  let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  if (value) {
    if (!reg.test(value)) {
      return callback(new Error("邮箱格式不正确!"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};


/**
 * 数字或者字母正则
 * @param {emaill} 参数名
 * @return {参数值}
 */
export const isNumberLetter = numberLetter => {
  var reg = /^[0-9a-zA-Z]{4,20}$/;
  if (!reg.test(numberLetter)) {
    return false;
  } else {
    return true;
  }
};

/**
 *
 */
export const isNumberLetterOne = numberLetter => {
  var reg = /^[0-9a-zA-Z]{1,20}$/;
  if (!reg.test(numberLetter)) {
    return false;
  } else {
    return true;
  }
};

/**
 * 密码格式验证
 * @param {password} 参数名
 * @return {参数值}
 */
export const isPassword = password => {
  let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
  if (reg.test(password)) {
    return true;
  } else {
    return false;
  }
};

/**
 * 校验规则 负号/数字/小数点
 */
export const ruleNegative  = (rule, value, callback) => {
  let reg = /^-?\d{1,9}([.]\d{1,2})?$/;
  if (value) {
    // value = value.replace(/[\u202D+\u202C+\s+]/g, "");
    if (!reg.test(value)) {
      callback(new Error("只能输入数字，最多保留两位小数"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

/**
 * 0~999999999.99校验规则
 */
export const rulePrice = (rule, value, callback) => {
  let reg = /^\d{1,9}(\.\d{1,2})?$/;
  if (value) {
    // value = value.replace(/[\u202D+\u202C+\s+]/g, "");
    if (!reg.test(value)) {
      callback(new Error("0.00 ~ 999999999.99"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

/**
 * 正数
 */
export const ruleNum0 = (rule, value, callback) => {
  let reg = /^\d{1,9}(\.\d{1,4})?$/;
  if (value) {
    // value = value.replace(/[\u202D+\u202C+\s+]/g, "");
    if (!reg.test(value)) {
      callback(new Error("不能小于0,且保留四位小数"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

/**
 * 手机号校验规则
 */
export const rulePhone = (rule, value, callback) => {
  let reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
  if (value) {
    value = value.replace(/[\u202D+\u202C+\s+]/g, "");
    if (!reg.test(value)) {
      callback(new Error("手机号格式不正确!"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
/**
 * 千位分隔符
 */
export const thousandBitSeparator = num => {
  num = num.toString();
  const reg = /\d{1,3}(?=(\d{3})+$)/g;
  let integer = "";
  let decimal = "";
  if (num.indexOf(".") > -1) {
    integer = num.split(".")[0];
    decimal = num.split(".")[1];
    return (integer + "").replace(reg, "$&,") + "." + decimal;
  } else {
    integer = num;
    return (integer + "").replace(reg, "$&,") + "." + "00";
  }
};

/**
 * 获取一个月多少天
 * @param {year} 年份
 * @param {month} 月份
 * @return {days} 天数number
 */
export const oneMonthDay = (year, month) => {
  // new data 参数二:月 默认月份从0 开始,这里不需要+1 ; 参数三:上一个月最后一天
  let days = new Date(year, month, 0).getDate()
  return days
};
/**
 * 获取一个月多少天
 * @param {time} 年份
 * @return {days} 天数number
 */
export const getMonthDay = time => {
  let nowTime = new Date(time)
  let year = nowTime.getFullYear()
  let month = nowTime.getMonth() + 1 // 默认月份从0 开始,这里需要+1
  let days = new Date(year, month, 0).getDate()
  return days
};
/**
 * 获取星期几
 * @param {time} 时间
 * @return {week} 周几
 */
export const getWeek = (time) => {
  let now = new Date(time);
  let day = now.getDay();
  let weeks = new Array(
    "周日",
    "周一",
    "周二",
    "周三",
    "周四",
    "周五",
    "周六"
  );
  var week = weeks[day];
  return week;
}
/**
 * 获取一个月的   周几/日期  
 * @param {time} 时间
 * @return {arrDateInfo} Array
 */
export const getWeekMonthInfo = (time) => {
  let timeAdd0 = function (num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return num;
    }
  };
  let nowTime = new Date(time)
  let year = nowTime.getFullYear()
  let month = nowTime.getMonth() + 1 // 默认月份从0 开始,这里需要+1
  // let day = nowTime.getDate()
  let days = new Date(year, month, 0).getDate()
  let arrDateInfo = []
  for (let index = 1; index <= days; index++) {
    arrDateInfo.push({
      week: getWeek(`${year}/${month}/${index}`),
      date: `${year}-${timeAdd0(month)}-${timeAdd0(index)}`
    })
  }
  return arrDateInfo;
}
/**
 * 获取截至今日的本月天数
 * @param {time} 时间
 * @return {arrDateInfo} Array
 */
export const getWeekDayMonthInfo = (time) => {
  let timeAdd0 = function (num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return num;
    }
  };
  let nowTime = new Date(time)
  let year = nowTime.getFullYear()
  let month = nowTime.getMonth() + 1 // 默认月份从0 开始,这里需要+1
  let day = new Date().getDate()
  let arrDateInfo = []
  for (let index = 1; index <= day; index++) {

    arrDateInfo.push({
      week: getWeek(`${year}/${month}/${index}`),
      date: `${year}-${timeAdd0(month)}-${timeAdd0(index)}`
    })
  }
  return arrDateInfo;
}
/**
 * 获取时间段的 -- 周几/日期  (不包含今天)
 * @param {time} 时间
 * @return {arrDate} Array
 */
export const getWeekDateSE = (start, end) => {
  let startTime = new Date(start).getTime()
  let endTime = new Date(end).getTime()
  let oneDayTime = 24 * 60 * 60 * 1000;
  let RangeTime = (endTime - startTime) / oneDayTime
  let arrDateInfo = [];
  for (let index = 0; index <= RangeTime; index++) {
    arrDateInfo.push({
      week: getWeek(startTime + (oneDayTime * index)),
      date: DateTransform(startTime + (oneDayTime * index))
    })
  }
  return arrDateInfo;
}
/**
 * 获取一周的   周几/日期  
 * @param {time} 时间
 * @return {arrDate} Array
 */
export const getWeekDateInfo = (time) => {
  let timeAdd0 = function (num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return num;
    }
  };
  let nowTime = new Date(time)
  let nowTimeNum = nowTime.getTime(); // 当前时间戳
  let oneDayTime = 24 * 60 * 60 * 1000; // 一天时间 --- 时间戳
  let week = nowTime.getDay() || 7 //为周日的时候 day 修改为7  否则当天周天会有问题   默认 0-6
  let MondayTime = nowTimeNum - (week - 1) * oneDayTime; //显示周一 --- （时间戳）
  let timeDate;
  let arrDateInfo = []
  for (let index = 0; index < 7; index++) {
    timeDate = new Date((index * oneDayTime) + MondayTime) // 从周一开始
    arrDateInfo.push({
      week: getWeek(timeDate),
      date: `${timeDate.getFullYear()}-${timeAdd0(timeDate.getMonth()+1)}-${timeAdd0(timeDate.getDate())}`
    })
  }

  return arrDateInfo;
}
/**
 * 获取近几天日期
 * @param {time} 时间
 * @return {arrDate} Array
 */
export const getLatelyDay = (days) => {
  let nowTime = new Date()
  let nowTimeNum = nowTime.getTime();
  let oneDayTime = 24 * 60 * 60 * 1000;
  let startTime = nowTimeNum - (oneDayTime * (days - 1))
  let arrDate;
  if (days == 0) { // 昨天
    startTime = nowTimeNum - oneDayTime
    arrDate = [new Date(startTime), new Date(startTime)]
  } else {
    startTime = nowTimeNum - (oneDayTime * (days - 1))
    arrDate = [new Date(startTime), nowTime]
  }
  return arrDate;
}
/**
 * 获取近几天日期-不包含今天
 * @param {time} 时间
 * @return {arrDate} Array
 */
export const getLatelyOneDay = (days) => {
  let nowTime = new Date()
  let nowTimeNum = nowTime.getTime();
  let oneDayTime = 24 * 60 * 60 * 1000;
  let startTime = nowTimeNum - (oneDayTime * (days - 1))
  let arrDate;
  if (days == 0) { // 昨天
    startTime = nowTimeNum - oneDayTime
    arrDate = [new Date(startTime), new Date(startTime)]
  } else {
    startTime = nowTimeNum - (oneDayTime * (days - 1))
    arrDate = [(new Date(startTime)).getTime() - (24 * 60 * 60 * 1000), nowTimeNum - (24 * 60 * 60 * 1000)]
  }
  return arrDate;
}

/**
 * 获取时间段的周几、日期
 * @param {time} 时间
 * @return {arrDate} Array
 */
export const getStartEndDate = (satrt, end) => {
  let startTime = new Date(satrt).getTime()
  let endTime = new Date(end).getTime()
  let oneDayTime = 24 * 60 * 60 * 1000;
  let RangeTime = (endTime - startTime) / oneDayTime
  let arrDateInfo = [];
  for (let index = 0; index <= RangeTime; index++) {
    arrDateInfo.push({
      week: getWeek(startTime + (oneDayTime * index)),
      date: DateTransform(startTime + (oneDayTime * index))
    })
  }
  return arrDateInfo;
}

/**
 * 获取本月、上月、季度、今年、去年开始
 * @param {datemethod} 类型
 * @return {startend} Array
 */
export const getSectionDate = (datemethod) => {
  let time = new Date();
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  let mm = m < 10 ? "0" + m : m;
  let startend;
  if (datemethod == 'lastyear') {
    let year = y - 1;
    startend = [year + "-" + "01" + "-" + "01",year + "-" + "12" + "-" + "31"]
  } else if (datemethod == 'month') {
    let date = new Date(y, mm, 0).getDate();
    startend = [y + "-" + mm + "-" + "01",y + "-" + mm + "-" + (date < 10 ? "0" + date : date)]
  }else if (datemethod == 'lastmonth') {
    // let lastDate = new Date(time.getTime() - 1000 * 3600 * 24 )
    // let lastY = lastDate.getFullYear()
    // let lastM = lastDate.getMonth() + 1
    // let lastMM = lastM < 10 ? "0" + lastM : lastM
    // let date1 = new Date(lastY, lastMM, 0).getDate();
    // startend = [lastY + "-" + lastMM + "-" + "01", lastY + "-" + lastMM + "-" + (date1 < 10 ? "0" + date1 : date1)];
    startend = [moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')];
  } else if (datemethod == 'jidu') {
    startend = [DateTransform(
      time.getTime() - 3600 * 1000 * 24 * 90
    ),DateTransform(time)] 
  } else if (datemethod == 'year') {
    startend = [y + "-" + "01" + "-" + "01",y + "-" + "12" + "-" + "31"]
  }
  return startend;
}

/**
 * @param {row} 
 */
export const tableColumn = (row, column) => {
  let returnStr;
  if (column.property == 'end_time' && row['end_time'] && row['start_time']) {
    returnStr = DateTransform(row['start_time']) + '~' + DateTransform(row['end_time'])
  } else if (row[column.property] === 0 || row[column.property] === 0.00) {
    returnStr = row[column.property]
  } else {
    returnStr = row[column.property] || '--'
  }
  return returnStr
}

/**
 * @param {row} 
 */
export const tableColumn2 = (row, column) => {
  let returnStr = row[column.property] || '/'
  return returnStr
}

/**
 * 时间戳转换-时分秒
 * @return {参数值}
 */
export const TimeTransformHMS = (date) => {
  let time = function (time) {
    if (time < 10) {
      return "0" + time;
    } else {
      return time;
    }
  };
  if (!date) {
    return null;
  }
  let newDate;
  newDate = new Date(date);
  let newHour = time(newDate.getHours());
  let newMinute = time(newDate.getMinutes());
  let newSecond = time(newDate.getSeconds());
  return `${newHour}:${newMinute}:${newSecond}`;
};

/**
 * 时间戳转换-年月日 时分秒
 * @return {参数值}
 */
export const TimeTransform = (date) => {
  let time = function (time) {
    if (time < 10) {
      return "0" + time;
    } else {
      return time;
    }
  };
  if (!date) {
    return null;
  }
  let newDate;
  if (typeof date == 'string' && date.indexOf('-') > -1) {
    newDate = new Date(date.replace(/-/g, '/'));
  } else {
    newDate = new Date(date);
  }
  let newYear = time(newDate.getFullYear());
  let newMonth = time(newDate.getMonth() + 1);
  let newDay = time(newDate.getDate());
  let newHour = time(newDate.getHours());
  let newMinute = time(newDate.getMinutes());
  let newSecond = time(newDate.getSeconds());
  return `${newYear}-${newMonth}-${newDay} ${newHour}:${newMinute}:${newSecond}`;
};

/**
 * 时间范围计算-计算范围时间多少小时
 * @return {参数值}
 */
export const RangeTimeNum = (startDate, endDate) => {

  let newStartDate;
  let newEndDate;
  if (startDate && typeof startDate == 'string' && startDate.indexOf(':') > -1) {
    let startH = startDate.split(":")[0];
    let startM = startDate.split(":")[1];
    let startS = startDate.split(":")[2];
    newStartDate = (Number(startH * 3600) + Number(startM * 60) + Number(startS)) * 1000
    
  }
  if (endDate && typeof endDate == 'string' && endDate.indexOf(':') > -1) {
    let endH = endDate.split(":")[0];
    let endM = endDate.split(":")[1];
    let endS = endDate.split(":")[2];
    newEndDate = (Number(endH * 3600) + Number(endM * 60) + Number(endS)) * 1000
  }
  // if (typeof startDate == 'string' && startDate.indexOf('-') > -1) {
  //   newStartDate = (new Date(startDate.replace(/-/g, '/'))).getTime();
  //   newEndDate = (new Date(endDate.replace(/-/g, '/'))).getTime();
  // } else {
  //   newStartDate = (new Date(startDate)).getTime();
  //   newEndDate = (new Date(endDate)).getTime();
  // }
  console.log(newStartDate, newEndDate)
  let TimeNum = ( newEndDate - newStartDate) / (1000 * 60 * 60)
  
  return (TimeNum).toFixed(2);
};

/**
 * 时间范围计算-计算范围时间多少天
 * @return {参数值}
 */
export const RangeDateNum = (startDate, endDate) => {

  let newStartDate;
  let newEndDate;
  if (typeof startDate == 'string' && startDate.indexOf('-') > -1) {
    newStartDate = new Date(startDate.replace(/-/g, '/'));
    newEndDate = new Date(endDate.replace(/-/g, '/'));
  } else {
    newStartDate = new Date(startDate);
    newEndDate = new Date(endDate);
  }
  let dateNum = ( newEndDate.getTime() - newStartDate.getTime()) / (1000 * 60 * 60 * 24)
  return `${dateNum}`;
};

/**
 * 时间转换
 * @return {参数值}
 */
export const DateTransCN = (date) => {
  let time = function (time) {
    if (time < 10) {
      return "0" + time;
    } else {
      return time;
    }
  };
  if (!date) {
    return null;
  }
  let newDate;
  if (typeof date == 'string' && date.indexOf('-') > -1) {
    newDate = new Date(date.replace(/-/g, '/'));
  } else {
    newDate = new Date(date);
  }
  let newYear = time(newDate.getFullYear());
  let newMonth = time(newDate.getMonth() + 1);
  let newDay = time(newDate.getDate());
  return `${newYear}年${newMonth}月${newDay}日`;
};

/**
 * 时间戳转换
 * @return {参数值}
 */
export const DateTransform = (date) => {
  let time = function (time) {
    if (time < 10) {
      return "0" + time;
    } else {
      return time;
    }
  };
  if (!date) {
    return null;
  }
  let newDate;
  if (typeof date == 'string' && date.indexOf('-') > -1) {
    newDate = new Date(date.replace(/-/g, '/'));
  } else {
    newDate = new Date(date);
  }
  let newYear = time(newDate.getFullYear());
  let newMonth = time(newDate.getMonth() + 1);
  let newDay = time(newDate.getDate());
  // let newHour = time(newDate.getHours());
  // let newMinute = time(newDate.getMinutes());
  // let newSecond = time(newDate.getSeconds());
  return `${newYear}-${newMonth}-${newDay}`;
};

/**
 * 时间戳转换
 * @return {参数值}
 */
export const DateTimeTransform = (date) => {
  let time = function (time) {
    if (time < 10) {
      return "0" + time;
    } else {
      return time;
    }
  };
  if (!date) {
    return null;
  }
  let newDate;
  if (typeof date == 'string' && date.indexOf('-') > -1) {
    newDate = new Date(date.replace(/-/g, '/'));
  } else {
    newDate = new Date(date);
  }
  let newYear = time(newDate.getFullYear());
  let newMonth = time(newDate.getMonth() + 1);
  let newDay = time(newDate.getDate());
  let newHour = time(newDate.getHours());
  let newMinute = time(newDate.getMinutes());
  let newSecond = time(newDate.getSeconds());
  return `${newYear}-${newMonth}-${newDay} ${newHour}:${newMinute}:${newSecond}`;
};


/**
 * 驼峰转下划线
 * @return {参数值}
 */
export const toLine = (name) => {
  // 驼峰转换下划线
  return name.replace(/([A-Z])/g, "_$1").toLowerCase();
};
/**
 * 数组按字段分组
 * @return {参数值}
 */
export const arrGroup = (data) => {
  let newArr = []
  let returnData = []
  data.map((item) => {
    newArr.push(DateTransform(item.create_time))
  })
  newArr = newArr.filter((item, index) => {
    return newArr.indexOf(item) == index
  })
  newArr.map((newItem) => {
    let itemList = []
    data.map((item) => {
      if (newItem == DateTransform(item.create_time)) {
        itemList.push(item)
      }
    })
    returnData.push({
      date: newItem,
      list: itemList
    })
  })
  return returnData
};

/**
 * 格式校验 -- 最多四位小数
 * @return {参数值}
 */
export const decimalFour = (rule, value, callback) => {
  if (!value) {
    callback();
    // callback(new Error("不能为空"));
  } else if (!/^[0-9]+([.]{1}[0-9]{0,4}){0,1}$/.test(value)) {
    callback(new Error("只能输入数字,最多保留四位小数"));
  } else {
    callback();
  }
};

/**
 * 格式校验 -- 纯数字
 * @return {参数值}
 */
export const decimalNull = (rule, value, callback) => {
  if (!value) {
    callback();
  } else if (!/^\d{1,}$/.test(value)) {
    callback(new Error("只能输入正整数"));
  } else {
    callback();
  }
};

/**
 * 列表换行- \n ,超出...
 * @return {参数值}
 */
export const commonLineFeed = (value) => {
  if (!value) {
    return '--'
  }
  let newArr = value.split(',')
  let newStr = ''
  newArr.map((item, index) => {
    let beyondStr
    if (item.length > 8) {
      beyondStr = item.slice(0, 3) + '...' + item.slice(item.length - 3, item.length)
    } else {
      beyondStr = item
    }
    if (newStr) {
      if (index == 2) {
        newStr += `\n...`
      } else if (index > 2) {
        newStr += ``
      } else {
        newStr += `\n${beyondStr}`
      }
    } else {
      newStr = beyondStr
    }
  })
  return newStr
};

/**
 * 是否超过num天
 * @return {参数值}
 */
export const overstepDateNum = (date, num) => {
  let newDate
  if (typeof date == 'string' && date.indexOf('-') > -1) {
    newDate = (new Date(date.replace(/-/g, '/'))).getTime()
  } else {
    newDate = (new Date(date)).getTime()
  }
  let currentDate = (new Date()).getTime()
  // let newDate = (new Date(date)).getTime()
  let isOverStep = (currentDate - newDate) > num * 1000 * 60 * 60 * 24
  return isOverStep
};
/**
 * 日期时间换行 - 加/n
 * @return {参数值}
 */
export const formatDateTimer = (date) => {
  if (date) {
    let newArr = date.split(' ')
    return `${newArr[0]}\n${newArr[1]}`
  } else {
    return '--'
  }
};
/**
 * list 根据某一值去重
 * arr： 数组；lable: 字段名
 * @return {参数值}
 */
export const duplicateRemoval = (arr, lable) =>{
  let result = []
  let len = arr.length
  for(let i = 0; i < len; i++){
    if (i == 0) {
      result.push(arr[i]) 
    } else {
      let isHave = false
      result.map((item) => {
        if (item[lable] == arr[i][lable]) {
          isHave = true
        }
      })
      if (!isHave) {
        result.push(arr[i]) 
      }
    }
  }
  return result;
 }